<template>
  <div>
        <keep-alive>
      <transition name="fade">
      <router-view />
      </transition>
    </keep-alive>
  </div>
</template>

<script>
export default {
  name: 'Door'
}
</script>

<style>

</style>